class GeoBanner extends HTMLElement {
  constructor() {
    super();

    this.geoBanner = this;
    this.dialog = this.geoBanner.querySelector('modal-redirect');

    // Close events
    this.addEventListener('keyup', (event) => {if (event.code.toUpperCase() === 'ESCAPE') this.close(this.dialog)});
    this.addEventListener('click', (event) => {if (event.target === this.dialog) this.close(this.dialog)});
    this.dialog.querySelector('#Stay').addEventListener('click', (event) => {if (event.target === this.dialog.querySelector('#Stay')) this.close(this.dialog)});

    // Check if designmode
    let geolocationShowInCustomizer = this.geoBanner.hasAttribute("data-designmode") ? true : false;

    // Check if popup already has been shown in this session
    const geolocationPopupShown = sessionStorage.getItem('geolocation-popup-shown');
    if (geolocationShowInCustomizer || !geolocationPopupShown) {
      // Fetching the users country to redirect probably
      this.fetchUserCountry(geolocationShowInCustomizer);
    }
  }

  fetchUserCountry(geolocationShowInCustomizer) {
    // Fetch the current country handle from a JSON availabile through the Geolocation app to see where the user is visiting from
    fetch('/browsing_context_suggestions.json')
    .then((res) => res.json())
    .then((data) => {

      // Prepare variables
      let userCountry = data.detected_values.country.handle;
      let openOnLoad = false;
      let targetUrl = 'false';
      let targetCountry = 'false';
      let currentCountry = 'false';
      let storeLocator = false;

      // This is the country check, it uses geolocation to pinpoint where the user is visiting from, eg. 'userCountry'. This returns their 2 letter CountryCode.
      // Then we check if the domain URL matches their current language
      if (Shopify.designMode && geolocationShowInCustomizer) {
        // Option to show the popup in the customizer - used to let our customer test design
        openOnLoad = true;
        targetUrl = window.shopUrl;
        targetCountry = userCountry;
        currentCountry = 'CUSTOMIZER';
      } else if (window.geoLocation.US.includes(userCountry) && window.shopUrl != window.domains.US && window.geoLocation.disable_popup_US !== true ) {
        openOnLoad = true;
        targetUrl = window.domains.US;
        targetCountry = 'US';
      } else if (window.geoLocation.JP.includes(userCountry) && window.shopUrl != window.domains.JP && window.geoLocation.disable_popup_JP !== true ) {
        openOnLoad = true;
        targetUrl = window.domains.JP;
        targetCountry = 'JP';
      } else if (window.geoLocation.DK.includes(userCountry) && window.shopUrl != window.domains.DK && window.geoLocation.disable_popup_DK !== true ) {
        openOnLoad = true;
        targetUrl = window.domains.DK;
        targetCountry = 'DK';
      } else if (window.geoLocation.SE.includes(userCountry) && window.shopUrl != window.domains.SE && window.geoLocation.disable_popup_SE !== true ) {
        openOnLoad = true;
        targetUrl = window.domains.SE;
        targetCountry = 'SE';
      } else if (window.geoLocation.PL.includes(userCountry) && window.shopUrl != window.domains.PL && window.geoLocation.disable_popup_PL !== true ) {
        openOnLoad = true;
        targetUrl = window.domains.PL;
        targetCountry = 'PL';
      } else if (window.geoLocation.DE.includes(userCountry) && window.shopUrl != window.domains.DE && window.geoLocation.disable_popup_DE !== true ) {
        openOnLoad = true;
        targetUrl = window.domains.DE;
        targetCountry = 'DE';
      } else if (!window.geoLocation.EU.includes(userCountry) && !window.geoLocation.US.includes(userCountry) && !window.geoLocation.JP.includes(userCountry) && !window.geoLocation.DK.includes(userCountry) && !window.geoLocation.SE.includes(userCountry) && !window.geoLocation.PL.includes(userCountry) && !window.geoLocation.DE.includes(userCountry) && window.geoLocation.disable_popup_EU !== true ) {
        openOnLoad = true;
        storeLocator = true;
        targetUrl = window.storeLocator.Url;
        targetCountry = 'Store Locator';
      }

      // Set current country code to match the domain, this is done manually to be able to print 'EU'.
      if (window.shopUrl == window.domains.EU) {
        currentCountry = 'EU';
      } else if (window.shopUrl == window.domains.US) {
        currentCountry = 'US';
      } else if (window.shopUrl == window.domains.JP) {
        currentCountry = 'JP';
      } else if (window.shopUrl == window.domains.DK) {
        currentCountry = 'JP';
      } else if (window.shopUrl == window.domains.SE) {
        currentCountry = 'JP';
      } else if (window.shopUrl == window.domains.PL) {
        currentCountry = 'JP';
      } else if (window.shopUrl == window.domains.DE) {
        currentCountry = 'JP';
      } else {
        currentCountry = window.Shopify.country;
      }

      // Run initialize popup
      if (targetUrl && openOnLoad) {
        this.initRedirectPopup(targetUrl, targetCountry, currentCountry, storeLocator)
      }
    });
  }

  initRedirectPopup(targetUrl, targetCountry, currentCountry, storeLocator) {

    // Add data attributes and redirect URL
    this.dialog.querySelector('#Redirect').setAttribute('href', targetUrl);

    // Clear the HTTP to make it more visually appealing when shown in frontend
    let friendlyTargetUrl = targetUrl.replace('https://','');

    // Replace text variables made in the customizer

    // Replace text variables made in the customizer
    let heading = this.dialog.querySelector('.geobanner-modal__heading');
    let replacedHeading = heading.textContent.replace('[current]', currentCountry).replace('[target]', friendlyTargetUrl);
    heading.textContent = replacedHeading;

    let text = this.dialog.querySelector('.geobanner-modal__text');
    let replacedText = text.textContent.replace('[current]', currentCountry).replace('[target]', friendlyTargetUrl);
    text.textContent = replacedText;

    if(storeLocator) {
      let buttonRedirect = this.dialog.querySelector('#Redirect');
      let replacedButtonRedirect = buttonRedirect.textContent.replace('[current]', currentCountry).replace('[target]', targetCountry);
      buttonRedirect.textContent = replacedButtonRedirect;
    } else {
      let buttonRedirect = this.dialog.querySelector('#Redirect');
      let replacedButtonRedirect = buttonRedirect.textContent.replace('[current]', currentCountry).replace('[target]', friendlyTargetUrl);
      buttonRedirect.textContent = replacedButtonRedirect;
    }

    let buttonStay = this.dialog.querySelector('#Stay');
    let replacedButtonStay = buttonStay.textContent.replace('[current]', currentCountry).replace('[target]', friendlyTargetUrl);
    buttonStay.textContent = replacedButtonStay;

    // Trigger the open state
    this.open(this.dialog);

  }

  open() {
    // Set session cookie, because popup only should show once.
    sessionStorage.setItem('geolocation-popup-shown', true);

    // Open popup
    this.dialog.setAttribute('open', true);

    // Add 'overflow:hidden' to remove the window scroll when popup is active
    document.body.classList.add('overflow-hidden');

    trapFocus(
      this.dialog.querySelector('[tabindex="-1"]'),
      this.dialog.querySelector('#Redirect')
    );
  }

  close() {
    this.dialog.removeAttribute('open');

    // Remove 'overflow:hidden' to again be able to scroll the window
    document.body.classList.remove('overflow-hidden');
  }

}

customElements.define('geo-banner', GeoBanner);